import React from "react";
import { FullWhitelabelConfigType } from "../services/whitelabelConfigSchema";
import { useSetCssPropertiesOnRoot } from "../../../modules/common/hooks/useSetCssPropertiesOnRoot";
import { useFullWhitelabelConfig } from "./WhitelabelConfig";

const configToCssProperties = (config: FullWhitelabelConfigType): Record<string, string | undefined> => {
  return {
    "--theme-page-background": config.themePageBackground,
    "--theme-page-foreground": config.themePageForeground,
    "--theme-primary": config.themePrimary,
    "--theme-primary-hover": config.themePrimaryHover,
    "--theme-on-primary": config.themeOnPrimary,
    "--theme-secondary": config.themeSecondary,
    "--theme-secondary-hover": config.themeSecondaryHover,
    "--theme-on-secondary": config.themeOnSecondary,
    "--theme-box-background": config.themeBoxBackground,
    "--theme-border": config.themeBorder,
    "--theme-meta": config.themeMeta,
  };
};

const WhitelabelTheme: React.FC = () => {
  const config = useFullWhitelabelConfig();
  const cssProperties = configToCssProperties(config);
  useSetCssPropertiesOnRoot(cssProperties);
  return null;
};

export default WhitelabelTheme;
